.questions{

.question {
  background-color: rgba(255, 255, 255, 1);
  margin-bottom:20px;
  border-radius:4px;
}

.question-row
{
  lost-utility: clearfix;
}

.question-title{
  padding: 15px;
  font-size:1.6em;
  text-align:left;

  a{
    color: $primary;
    text-decoration:none;
  }

  a:hover{
    font-weight:bold;
  }

  .fa{
    margin-right:10px;
  }

}

.question-answer
{
  padding: 15px 30px 30px 30px;
  font-size:1.4em;
  text-align:left;

}

  @media screen and (--extra-small-viewport){
    .question
    {
      lost-column : 1/1;
    }

    .question-row{
      margin-bottom:0;
    }
  }

  @media screen and (--small-viewport) {
    .question{
      lost-waffle: 1/2;
    }
    .question-row{
        margin-bottom:20px;
    }
  }


}
