.contact-options
{
  padding:0;
  margin-bottom:50px;
}

.contact-option{
  display: inline-block;
  margin-bottom:15px;

  .icon
  {
    display:inline-block;
    margin-left:40px;
    color: rgba(255, 255, 255, 1);

    i{
      font-size:2.4em;
      width:50px;
      height:50px;
      background: rgba(0,0,0, 0.25);
      text-align:center;
      background-clip: padding-box;
      border-radius:50%;
      line-height:50px;
      margin-bottom:15px;
    }

    .detail{
      display:block;
      font-size: 1.4em;
      font-weight:bold;
    }

  }

  a{
    text-decoration:none;
  }

  a:hover{
    text-decoration:underline;
  }
}

  @media screen and (--small-viewport){
    #contact-form
    {
      padding:60px;
      max-width: 760px;
      background: rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      background-clip:padding-box;
    }
  }


#contact-form{

  margin:0 auto;
  margin-bottom: 30px;
  text-align:left;

  label{
    display:block;
    font-size:1.6em;
  }

  .form-input{
    display:block;
    width:100%;
    margin-bottom:0;
    font-size: 1.6em;
    height:40px;
    padding: 6px 12px;
    border-radius: 4px;
    border:none;
  }

  textarea.form-input{
    height:auto;
  }
}

.form-group{
  margin-bottom:30px;
}

.btn-contact-submit{
  background-color: #7DCE48;
  color: #FFF;
}
