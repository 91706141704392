#services{
  lost-utility: clearfix;

  @media screen and (--extra-small-viewport) {
    .service {
      lost-column: 1/1;
    }
  }

  @media screen and (--small-viewport) {
    .service{
      lost-column: 1/2;
    }
  }

  @media screen and (--medium-viewport) and (--large-viewport) {
    .service{
      lost-column: 1/3;
    }
  }

  .service-icon{
    color: $primary;
    font-size:4em;
  }


  .service{
    margin-bottom:60px;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;



    h3{
      font-size: 2.2em;
      color: color($primary shade(52%));
    }
  }

}
