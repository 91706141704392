.slide
{
  margin:0;
  padding:0;
  overflow-y:hidden;
  max-height:0;
}

.slide.vertical.out
{
  transition-timing-function: ease-in;
  transform: scaleY(0);
  transition:0.2s;

}

.slide.vertical.in{
  transform: scaleY(1);
  transition:0.5s;
  max-height:400px;
}
