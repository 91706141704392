.social-items
{
  padding:0;
  list-style:none;

  &.top-bar{
    position:absolute;
    top:-2px;
  }
}


.social-item{
  display: inline-block;

  a{
    display:inline-block;
    background: rgba(255,255,255, 0.1);
    text-align:center;
    background-clip: padding-box;
    border-radius:50%;
    width:35px;
    height:35px;
    font-size:2.3em;
    margin-right:10px;
    line-height:35px;
    transition: all 0.4s ease-in-out;
  }

  i{
    color: rgba(255, 255, 255, 0.6);
  }

  a:hover{
    background-color: rgba(255, 255 ,255, 0.2);
    i{
      color: rgba(255,255,255, 0.9)
    }
  }
}


@media screen and (--extra-small-viewport) {
  .social-items
  {
    &.top-bar
    {
        right: auto;
        left: 0;
        margin-left:20px;
    }

  }
}


@media screen and (--small-viewport) {
  .social-items
  {
    &.top-bar
    {
      left:auto;
      right:30px;
    }
  }
}
