#footer{
  text-align:center;
  background-color:  color($primary shade(52%));
  padding-top:60px;
  padding-bottom:60px;

  .footer-content{
    lost-center: 1100px 30px;
    lost-utility: clearfix;
  }

  .footer-col{
    text-align:left;
    padding-left:30px;
    padding-right:30px;
  }

  @media screen and (--extra-small-viewport) {
    .footer-col {
      lost-column: 1/1;
    }
  }

  @media screen and (--small-viewport) {
    .footer-col {
      lost-column: 1/2;
    }
  }


  @media screen and (--medium-viewport) and (--large-viewport) {
    .footer-col{
      lost-column: 1/3;
    }
  }

  .detail, .items {
    font-size: 1.4em;
  }

  .detail, .items, a, i{
    color: rgba(255, 255 ,255, 0.9);
  }

  .col-title{
    font-size: 1.8em;
    color: rgba(255, 255 ,255, 1);
  }

  a {
    text-decoration:none;
  }

  a:hover{
    color: rgba(255, 255 ,255, 1);
    text-decoration:underline;
  }

  .items{
    padding: 0;
    list-style:none;

    .fa{
      font-size:1.6em;
      line-height:1.6em;
      vertical-align:middle;
      margin-right:5px;
      width:25px;
    }
  }

}
