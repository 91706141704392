.gallery{
  .gallery-item{
    margin-bottom : 15px;
  }

  @media screen and (--extra-small-viewport) {
    .gallery-item {
      lost-column: 1/3;
    }
  }

  @media screen and (--medium-viewport) {
    .gallery-item{
      lost-column: 1/4;
    }
  }

  @media screen and (--large-viewport) {
    .gallery-item{
      lost-column: 1/5;
    }
  }


    @media screen and (--extra-large-viewport) {
      .gallery-item{
        lost-column: 1/6;
      }
    }


}
