.callout-section
{
  padding-top:45px;
  padding-bottom:45px;
  color : #FFF;
}

.btn-callout{
  background-color: #7DCE48;
  color: #FFF;
  text-decoration:none;
}
