body {
  font-family: 'Lato', sans-serif;
  color: $aresenic;
  font-size:62.5%;
  line-height: 1.42857143;
}

h1, h2, h3, h4, h5, h6{
  font-weight:700;
  line-height:1.1;
  margin-bottom:1em;
}

p,h1,h2,h3,h4,h5, h6{
  color: #555;
}

h1{
  font-size:3em;
}

h2{
  font-size: 2.8em;
}

h3{
  font-size: 2.6em;
}

h4{ font-size: 2.4em; }

.section-heading
{
  margin-top: 0;
  margin-bottom: 30px;
  font-weight: 800;
  font-size: 2.8em;
  margin-left: auto;
  margin-right: auto;
}

.section-intro
{
  margin-top: 0;
  margin-bottom: 60px;
  margin-left: auto;
  margin-right: auto;
}

p, pre { font-size: 1.6em; line-height:1.5; }
