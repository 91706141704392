#pitch
{
  .section-heading { color: #FFF; }

  .reason-title{
    color: #FFF;
    font-size:1.8em;

    .fa-check{
      color: #D8FF70;
    }
  }

  .reason-description {
   color: color($primary tint(60%));
   font-size:1.4em;
   margin-bottom:2.5em;
  }
}
