.section, .section-content
{
  &.centered
  {
    text-align:center;
    lost-center: 100% 30px;
  }

  &.centered.medium
  {
    lost-center: 980px 30px;
  }

  &.coloured{
    background-color: $primary;

    p,h1,h2,h3,h4,h5, a, label{
      color: #FFF;
    }

    &.darker-2{
      background-color: color($primary shade(20%));
    }

    &.darker-4{
      background-color: color($primary shade(40%));
    }

    &.subtle{
      background-color: #f5f5f5;

      p,h1,h2,h3,h4,h5{
        color: rgba( 0,0,0, 0.7);
      }
    }
  }
}

.section {
  padding-top:90px;
  padding-bottom:90px;
}
