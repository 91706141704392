
.promo-1
{
  background: url(../images/sunflowers.jpg);
  background-size:cover;
  background-color:color($primary shade(70%));

  height:520px;
}

.promo-2
{
  background: url(../images/promo-6.png);
  background-repeat:repeat;
  background-color:color($primary shade(70%));

  height:520px;
}

.promo-3
{
  background-image: url(../images/promo-9.png);
  background-repeat: repeat;
  height:520px;
  background-color:color($primary shade(70%));
}

.promo-item{
  background-position: center center;
}

.promo-item.promo-3{
  background-position:initial;
}


.promo.carousel{
  height:520px;
}
