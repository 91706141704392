.logo {
  i{
    color: $primary;
    margin-right:15px;
  }
  font-family: 'Roboto', sans-serif;
}

.contact-item{
  position:relative;
}

.contact-details
{
  list-style:none;
  .icon
  {
    font-size: 30pt;
    left:0;
    top:0;
    line-height:1;
    position: absolute;
    color: $primary;
  }

  a{
    text-decoration:none;
    color: inherit;
  }

  a:hover{
    text-decoration:underline;

  }

  .sub, .primary
  {
    display:block;
    margin-left:45px;
    margin-right:30px;
  }

  .sub{
    font-size:1.3em;
  }

  .primary{
    font-size:1.5em;
    font-weight:bold;
  }
}


#brand
{
  position:relative;
  padding:30px;

  @media screen and (--extra-small-viewport) {
    & {
      text-align:center;
    }

    .logo-image
    {

      margin-left: auto;
      margin-right:auto;
      margin-bottom:35px;
      display:block;
      top:0;
    }

    .contact-details{
      margin: 0 auto;
      display:inline-block;
      text-align:left;
    }

    .logo, .contact-item {
          margin-bottom:30px;
      }
  }

  @media screen and (--medium-viewport){
    & {
      lost-center: 1170px;
    }

    .logo { float: left; margin:0;}
    .contact-details { float : right; padding-left:0; }
    .logo, .contact-detials, .contact-item { display:inline-block; margin-bottom:0;}

    .logo-image
    {
      margin-top:0;
      top:0;
      left:0;
      position:absolute;
    }

  }

}
