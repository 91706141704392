html {
  box-sizing: border-box;
  overflow:auto;
  overflow-x:hidden;
}
*, *:before, *:after {
  box-sizing: inherit;
}

.pull-left{
  float:left;
}

.pull-right{
  float: right;
}

ul.unstyled
{
  padding:0;
  list-style:none;
}

img.responsive{
  display:block;
  max-width:100%;
  height:auto;
}

.hide
{
  display:none;
}
