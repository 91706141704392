.nav {
  &.default {
    background-color: color($primary shade(15%));
    color: rgba(255, 255 ,255, 0.8);

    a{
      color:inherit;
      text-decoration:none;
    }

    a:hover{
      color:rgba(255,255,255,1);
    }
  }

  &.fixed-top{
    position: fixed;
    margin:auto;
    height: 50px;
    top: 0;
    left:0;
    right:0;
    z-index: 20;

    &+.section{
      margin-top:50px;
    }

    & .nav-item, & nav-item {
      height:50px;
      line-height:50px;
      vertical-align:middle;
    }
  }
}

.nav-items{
  list-style: none;
  margin:0;
  padding:0;
}

.nav-item, nav-item{
  margin:0 0 0 30px;
  padding:0;
  text-transform:uppercase;
  font-size:9pt;
  font-weight:bold;
}

.navbar-toggle{
  margin-right:0;
  margin-top:0;
  background:none;
  position: relative;
  top : 6px;
  float: right;
  padding: 9px 10px;
  border: 1px solid transparent;
  border-radius:4px;
}

.navbar-toggle{
  color:rgba(255, 255 ,255, 0.8);
  cursor: pointer;
}

.navbar-toggle:hover{
  color:rgba(255, 255 ,255, 1);
}

@media screen and (--extra-small-viewport) {
  .navbar-toggle, .nav-collapse, .nav-items, .nav-item, nav-item, .navbar-header{
    display:block;
    text-align:center;
  }

  .navbar-header{
    height:100%;
    z-index:2;
  }

  .nav-collapse{

    transition: all 0.5s ease-in-out;
    transform:translateX(-150%);
  }

  .nav-collapse.in
  {
    z-index:1;
    transform:translateX(0px);
    transition: all 0.5s ease-in-out;

  }

  .nav-items{
    background-color: color($primary shade(15%));
    max-height: 350px;
    overflow-y:auto;
  }

}

@media screen and (--small-viewport) {
  .navbar-toggle{
    display:none;
  }

  .nav-collapse {
    transition: none;
    transform:translateX(0px);
  }

  .nav-items, .nav-item, nav-item{
    display:inline-block;
  }

  .navbar-header{
    float:left;
  }
}
