.btn{
  border:none;
  font-size:2em;
  display:inline-block;
  text-align:center;
  vertical-align:middle;
  cursor:pointer;
  border-radius: 4px;
  padding:6px 12px;

  &.btn-block
  {
    display:block;
    width:100%;
  }

  &.btn-primary
  {
    color: #FFF;
    background-color: $primary;
  }

}
